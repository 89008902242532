//=================================================
// Languages
//=================================================

.languages {
    .nav {
        font-size: $f_size_lg;

        .lang-item {
            margin-left: $space_xs;
            margin-bottom: 0;
            line-height: 0.7;

            &:before, &:after {
                display: none;
            }

            &:first-child {
                margin-left: 0;
            }

            a {
                font-family: $font_primary_bold;
                font-weight: 700;
            }

            &.active, &.current-lang {
                a {
                    cursor: not-allowed;

                    &:hover {
                        opacity: 1 !important;
                    }
                }
            }
        }

        &.flex-column {
            .lang-item {
                margin-left: 0;
                margin-bottom: $space_xs;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.lang-position {
    position: absolute !important;
    width: auto !important;
    bottom: $space_lg;
}
